import styled from 'styled-components'

export const Title = styled.h1`
  font-size: 2rem;
  word-break: break-word;
  & > span {
    font-size: 50%;
    display: block;
    font-weight: 400;
    margin: 10px 0px;
  }
`
export const Heading = styled.h2`
  font-size: 1.5rem;
  word-break: break-word;
  &.latest {
    font-size: 2rem;
  }
  & > span {
    font-size: 50%;
    color: #00000060;
    font-weight: 400;
    display: block;
    margin: 10px 0px;
  }
`
