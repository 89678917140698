import React from 'react'
import Helmet from 'react-helmet'

const META = ({ title }) => {
  return (
    <Helmet>
      <title>
        {title
          ? `${title} 🍿 Biobrudarna`
          : 'Biobrudarna 🍿 Ta med dig bioupplevelsen hem'}
      </title>
      <meta
        name="description"
        content="Vi är två glada filmälskare som ville komma på ett sätt att spara alla film-minnen vi haft och förhoppningsvis kommer att få."
      />
      <meta
        name="google-site-verification"
        content="WAur6vgK6d952EdgG4RqU49ig63XiJ66Z4fD2LuNkzk"
      />

      <html lang="sv" />
    </Helmet>
  )
}

export default META
